<template>
    <div
        class="uk-card-body uk-width-1-1"
        style="border-radius: 10px"
        :style="spSent ? 'border: solid #D8020F; background-color: #D8020F0C' : isHold ? 'border: solid #FEBE10; background-color: #fedd80': ''"
    >
        <div class="uk-flex uk-flex-between uk-flex-middle" :class="[select ? 'uk-margin-medium-left' : '']" style="width: 100%">
            <div class="uk-flex uk-flex-center uk-flex-middle">
                <div>
                    <img v-if="!photoUrl" :data-src="`${images}/avatar.svg`" alt="Avatar Icon" uk-img class="photo">
                    <img v-else-if="photoUrl" :data-src="photoUrl.file_url" @error="(e) => imgError(e)" alt="Avatar Icon" uk-img class="photo">
                </div>
                <div class="uk-margin-medium-left" style="color: black">
                    <div class="uk-flex" style="gap: 10px">
                        <p class="uk-margin-remove uk-text-bold">{{ fullname }}<span v-if="haveSp" @click="$emit('view-sp-req')" class="sp-warning">!</span></p>
                        <div
                            :style="{
                                borderRadius: '10px',
                                padding: '1px 10px',
                                color: 'white',
                                background: isDocComplete ? '#42A5F5' : '#EF5350'
                            }"
                        >
                            <img :src="`${images}/${isDocComplete ? 'mdi_progress-check' : 'mdi_progress-alert'}.svg`">
                            Documents Completion
                        </div>
                        <div v-if="docComplete && !isDocComplete" class="uk-dropdown-large" uk-dropdown>
                            <ul class="uk-nav uk-dropdown-nav">
                                <li class="uk-nav-header">Completed Document(s):</li>
                                <li
                                    class="uk-margin-left"
                                    style="list-style: square;"
                                    v-for="(data) in docComplete.completeDocList"
                                    :key="data"
                                >
                                    {{ data }}
                                </li>
                                <li class="uk-nav-header">The following document(s) need to be added:</li>
                                <li
                                    class="uk-margin-left"
                                    style="list-style: square;"
                                    v-for="(data) in docComplete.incompleteDocList"
                                    :key="data"
                                >
                                    {{ data }}
                                </li>
                            </ul>
                        </div>
                        <div
                            :style="{
                                borderRadius: '10px',
                                padding: '1px 10px',
                                color: 'white',
                                background: isDocValid ? '#5FD855' : '#FFC737'
                            }"
                        >
                            <img :src="`${images}/${isDocValid ? 'material-symbols_verified-rounded' : 'material-symbols_warning-rounded'}.svg`">
                            Documents Validation
                        </div>
                        <div v-if="docValid && !isDocValid" class="uk-dropdown-large" uk-dropdown>
                            <ul class="uk-nav uk-dropdown-nav">
                                <li class="uk-nav-header">Validated Document(s):</li>
                                <li
                                    class="uk-margin-left"
                                    style="list-style: square;"
                                    v-for="(data) in docValid.validDocList"
                                    :key="data"
                                >
                                    {{ data }}
                                </li>
                                <li class="uk-nav-header">Invalid Document(s)</li>
                                <li
                                    class="uk-margin-left"
                                    style="list-style: square;"
                                    v-for="(data) in docValid.invalidDocList"
                                    :key="data"
                                >
                                    {{ data }}
                                </li>
                                <li class="uk-nav-header">The following document(s) need to be validated:</li>
                                <li
                                    class="uk-margin-left"
                                    style="list-style: square;"
                                    v-for="(data) in docValid.notValidatedList"
                                    :key="data"
                                >
                                    {{ data }}
                                </li>
                            </ul>
                        </div>
                        <div
                            :style="{
                                borderRadius: '10px',
                                padding: '1px 10px',
                                color: isDataComplete ? '#42A5F5' : '#EF5350',
                                border: isDataComplete ? '1px solid #42A5F5' : '1px solid #EF5350'
                            }"
                        >
                            <img :src="`${images}/${isDataComplete ? 'mdi_progress-check-biru' : 'mdi_progress-alert-merah'}.svg`">
                            Data Completion
                        </div>
                        <div v-if="dataComplete && !isDataComplete" class="uk-dropdown-large" uk-dropdown>
                            <ul class="uk-nav uk-dropdown-nav">
                                <li class="uk-nav-header">Completed Data:</li>
                                <li
                                    class="uk-margin-left"
                                    style="list-style: square;"
                                    v-for="(data) in dataComplete.completeDataList"
                                    :key="data"
                                >
                                    {{ data }}
                                </li>
                                <li class="uk-nav-header">The following data need to be added:</li>
                                <li
                                    class="uk-margin-left"
                                    style="list-style: square;"
                                    v-for="(data) in dataComplete.incompleteDataList"
                                    :key="data"
                                >
                                    {{ data }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <p class="uk-margin-remove">Employee Id: {{ employeeId }}</p>
                    <p class="uk-margin-remove">Office: {{ officeName }}</p>
                    <p class="uk-margin-remove">Role: {{ role }}</p>
                    <p class="uk-margin-remove">Start Date: {{ formatDate(startDate) }}</p>
                    <p class="uk-margin-remove">End Date: {{ formatDate(endDate) }}</p>
                    <p v-if="contractStatus && contractStatus > 0" class="uk-margin-remove">
                        <span class="red-highlight">
                            <span class="sp-warning" style="border-color: #ffffff">!</span>
                            {{ contractStatus == 1 ? 'Mitra’s Contract is about to expire' : 'Mitra’s Contract has expired' }}
                        </span>
                    </p>
                    <p class="uk-margin-remove">
                        <span>Status: </span>
                        <span class="uk-text-bolder" v-if="isHold" style="color: #FEBE10;">HOLD</span>
                        <span v-else>{{ checkStatus }}</span>
                    </p>
                    <p class="uk-margin-remove">Source: {{ source }}</p>
                    <p class="uk-margin-remove">Contract Status: {{ contractSignedStatus || '-' }}</p>
                    <p class="uk-margin-remove">Employment Type: {{ employmentType }}</p>
                </div>
            </div>
            <div v-if="!select" class="uk-grid-small uk-text-center" uk-grid>
                <div class="uk-flex uk-flex-column" style="gap: 10px;">
                    <button
                        class="uk-button"
                        style="width: 200px; border-radius: 5px; background-color: #0ABAB5; color: black;"
                        @click="$router.push({ path: `/admin/workers/mitra/${userId}`, query: $route.query })"
                    >Details</button>
                    <button
                        v-if="$can('EDIT', 'worker-mitra')"
                        class="uk-button"
                        style="width: 200px; border-radius: 5px;"
                        :style="{ backgroundColor: isHold ? '#DDD8D8' : '#FEBE10', color: isHold ? '#888' : 'black' }"
                        :disabled="isHold"
                        @click="$emit('move')"
                    >Move or Change Role</button>
                    <button
                        v-if="$can('EDIT', 'worker-mitra')"
                        class="uk-button"
                        style="width: 200px; border-radius: 5px; background-color: #42A5F5; color: #fff;"
                        @click="$emit('multiple-validation')"
                    >Document Validation</button>
                    <!-- <button
                        class="uk-button"
                        style="width: 200px; border-radius: 5px;"
                        :style="{ backgroundColor: isHold ? '#DDD8D8' : '#ffffff', color: isHold ? '#888' : '#D8020F', border: isHold ? '' : '2px solid #D8020F' }"
                        v-if="$can('EDIT', 'worker-mitra')"
                        :disabled="isHold"
                        @click="handleSpMitra()"
                    >{{ spSent ? 'View SP Details' : 'Send SP' }}</button>
                    <button
                        class="uk-button uk-button-default"
                        style="width: 200px; border-radius: 5px;"
                        v-if="$can('EDIT', 'worker-mitra')"
                        :style="{ backgroundColor: isHold ? '#DDD8D8' : '#ffffff', color: isHold ? '#888' : '#0ABAB5', borderColor: isHold ? '' : '#0ABAB5' }"
                        :disabled="isHold"
                        @click="$emit('renew')"
                    >Renew Contract</button> -->
                </div>
                <div class="uk-flex uk-flex-column" style="gap: 10px;">
                    <!-- <button
                        class="uk-button uk-button-default"
                        style="width: 200px; border-radius: 5px; background-color: white; border-color: #FEBE10; color: #FEBE10;"
                        v-if="$can('EDIT', 'worker-mitra')"
                        @click="$emit('hold-unhold')"
                    >{{isHold ? 'Un-Hold' : 'Hold'}}</button>
                    <button
                        class="uk-button"
                        style="width: 200px; border-radius: 5px; background-color: #D8020F; color: white;"
                        v-if="$can('DELETE', 'worker-mitra')"
                        @click="$emit('delete')"
                    >Delete</button>
                    <button
                        class="uk-button uk-button-default"
                        style="width: 200px; border-radius: 5px;"
                        :style="{ backgroundColor: isHold ? '#DDD8D8' : 'white', color: isHold ? '#888' : 'black', borderColor: isHold ? '' : 'black' }"
                        v-if="$can('READ', 'worker-mitra')"
                        :disabled="isHold"
                        @click="$emit('download-contract')"
                    >Download Contract</button> -->
                    <button
                        v-if="$can('EDIT', 'worker-mitra')"
                        class="uk-button"
                        style="width: 200px; border-radius: 5px; background-color: #EF5350; color: #fff;"
                        @click="$emit('mitra-action')"
                    >Action</button>
                    <button
                        v-if="$can('DELETE', 'worker-mitra')"
                        class="uk-button uk-button-default"
                        style="width: 200px; border-radius: 5px; background-color: #fff; border-color: #9E9E9E;"
                        @click="$emit('mitra-contract')"
                    >Contract</button>
                    <button
                        class="uk-button uk-button-default"
                        style="width: 200px; border-radius: 5px; border-color: #0ABAB5; color: #0ABAB5;"
                        @click="$emit('reblast-notify')"
                    >Reblast Notification</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formatter from "@/utils/formatter";
import { PREFIX_IMAGE } from "@/utils/constant";

export default {
    name: 'MitraItem',
    props: {
        userId: {
            type: String,
            required: true
        },
        photoUrl: {
            type: Object,
            required: false,
            default: () => {}
        },
        fullname: {
            type: String,
            required: false,
            default: '-'
        },
        employeeId: {
            type: String,
            required: false,
            default: '-'
        },
        officeName: {
            type: String,
            required: false,
            default: '-'
        },
        role: {
            type: String,
            required: false,
            default: '-'
        },
        startDate: {
            type: String,
            required: false,
            default: '-'
        },
        endDate: {
            type: String,
            required: false,
            default: '-'
        },
        status: {
            type: Number,
            required: false,
            default: null
        },
        select: {
            type: Boolean,
            required: false,
            default: false
        },
        source: {
            type: String,
            required: false,
            default: '-'
        },
        isHold: {
            type: Boolean,
            required: false,
            default: false
        },
        haveSp: {
            type: Boolean,
            required: false,
            default: false
        },
        spSent: {
            type: Boolean,
            required: false,
            default: true
        },
        contractSignedStatus: {
            type: String,
            required: false,
            default: '-'
        },
        contractStatus: {
            type: Number,
            required: false,
            default: 0
        },
        isDocComplete: {
            type: Boolean,
            required: false,
            default: false
        },
        isDocValid: {
            type: Boolean,
            required: false,
            default: false
        },
        isDataComplete: {
            type: Boolean,
            required: false,
            default: false
        },
        docComplete: {
            type: Object,
            default: () => []
        },
        docValid: {
            type: Object,
            default: () => []
        },
        dataComplete: {
            type: Object,
            default: () => []
        },
        employmentType: {
            type: String,
            required: false,
            default: '-'
        },
    },
    data() {
        return {
            images: PREFIX_IMAGE,
        };
    },
    computed: {
        checkStatus() {
            return this.status === null ? '-' : this.status === 1 ? 'Active' : 'Not Active';
        },
    },
    methods: {
        handleSpMitra() {
            if (this.spSent) {
                this.$emit('view-sp-sent');
            } else {
                this.$emit('send-sp');
            }
        },
        formatDate(data) {
            if (data === null) {
                return "-";
            }
            return formatter.dateCompleteNew(data);
        },
        expiredContract(date) {
            if (date) {
                return date < this.now;
            } else {
                return false;
            }
        },
        imgError(e) {
            e.target.src = `${this.images}/avatar.svg`;
        },
    }
};
</script>

<style scoped>
.photo {
    width: 106px;
    height: 106px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}
.sp-warning {
    padding: 1px 6px;
    background-color: #D8020F;
    border: 1px solid #D8020F;
    border-radius: 100%;
    color: white;
    font-weight: bold;
    margin-left: 10px;
}
.red-highlight {
    background-color: #D8020F;
    color: #ffffff;
    padding: 1px 10px 1px 1px;
    border: solid 1px #D8020F;
    border-radius: 5px;
}
</style>
